import { Router } from 'next/router';
import NProgress from 'nprogress';
import { useEffect, useRef, useState } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

const WAIT_FOR_SUBSEQUENT_LOADING_TIMEOUT_MS = 100;

interface Props {
  useApolloNetworkStatus: ReturnType<typeof createNetworkStatusNotifier>['useApolloNetworkStatus'];
}

const AppLoadingBar = ({ useApolloNetworkStatus }: Props) => {
  const apolloNetworkStatus = useApolloNetworkStatus();

  const previousIsBusyRef = useRef<boolean>(false);
  const waitForSubsequentLoadingTimeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [routerChangeInProgress, setRouterChangeInProgress] = useState<boolean>(false);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    const routeChangeStartHandler = () => {
      setRouterChangeInProgress(true);
    };
    const routeChangeStopHandler = () => {
      setRouterChangeInProgress(false);
    };

    Router.events.on('routeChangeStart', routeChangeStartHandler);
    Router.events.on('routeChangeComplete', routeChangeStopHandler);
    Router.events.on('routeChangeError', routeChangeStopHandler);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStartHandler);
      Router.events.off('routeChangeComplete', routeChangeStopHandler);
      Router.events.off('routeChangeError', routeChangeStopHandler);
    };
  }, []);

  const isBusy = routerChangeInProgress || apolloNetworkStatus.numPendingQueries > 0;

  if (isBusy !== previousIsBusyRef.current) {
    if (isBusy) {
      if (!waitForSubsequentLoadingTimeoutIdRef.current) {
        NProgress.start();
      } else {
        clearTimeout(waitForSubsequentLoadingTimeoutIdRef.current);
        waitForSubsequentLoadingTimeoutIdRef.current = null;
      }
    } else if (!waitForSubsequentLoadingTimeoutIdRef.current) {
      waitForSubsequentLoadingTimeoutIdRef.current = setTimeout(() => {
        waitForSubsequentLoadingTimeoutIdRef.current = null;
        NProgress.done();
      }, WAIT_FOR_SUBSEQUENT_LOADING_TIMEOUT_MS);
    }

    previousIsBusyRef.current = isBusy;
  }

  return null;
};

export default AppLoadingBar;
