import LmcCookieConsentManager, {
  DisplayMode,
  SecondaryButtonMode,
  VanillaCookieConsent,
} from '@lmc-eu/cookie-consent-manager';
import { CookieConsentCategoryValues } from '@lmc-eu/cookie-consent-manager/types';
import { Cookies } from 'react-cookie';
import logger from '@libs/logger';
import { startsWith } from '@libs/stringUtils';
import { removeCookieFromCurrentDomainAndAncestors } from './removeCookieFromCurrentDomainAndAncestors';
import { CookiesConstants } from '../../generated/phpConstants';
import { CookieNamesIndexedByCategory } from '../../graphql/generated/types/graphqlTypes';

const cookies = new Cookies();

const removeCookiesNotMatchingPrefixes = (prefixes: string[]) => {
  Object.keys(cookies.getAll())
    .filter((savedCookieName) => !prefixes.some((prefix) => startsWith(savedCookieName, prefix)))
    .forEach((savedCookieWithoutMatchingPrefix) =>
      removeCookieFromCurrentDomainAndAncestors(savedCookieWithoutMatchingPrefix),
    );
};

const removeBrowserStorageKeysNotMatchingPrefixes = (storage: Storage, prefixes: string[]) => {
  Object.keys(storage)
    .filter((savedKey) => !prefixes.some((prefix) => startsWith(savedKey, prefix)))
    .forEach((savedKeyWithoutMatchingPrefix) => storage.removeItem(savedKeyWithoutMatchingPrefix));
};

export const initLmcCookiesConsentManager = (language: string, cookieNamesByCategory: CookieNamesIndexedByCategory) => {
  const getAllKnownCookiePrefixes = () => {
    let allKnownCookiePrefixes: string[] = [];
    Object.keys(cookieNamesByCategory).forEach((categoryName) => {
      // @ts-ignore
      allKnownCookiePrefixes = allKnownCookiePrefixes.concat(cookieNamesByCategory[categoryName]);
    });

    return allKnownCookiePrefixes;
  };

  const logCookiesWithUnknownPrefixes = () => {
    const allKnownCookiePrefixes = getAllKnownCookiePrefixes();

    const cookiesWithoutKnownPrefix: string[] = Object.keys(cookies.getAll()).filter(
      (savedCookieName) =>
        !allKnownCookiePrefixes.some((knownCookiePrefix: string) => startsWith(savedCookieName, knownCookiePrefix)),
    );

    if (cookiesWithoutKnownPrefix.length > 0) {
      logger.error(`Found unknown cookies: "${cookiesWithoutKnownPrefix.join('", "')}". These will be deleted.`);
    }
  };

  const logBrowserStorageKeysWithUnknownPrefixes = (storage: Storage, storageName: string) => {
    const allKnownCookiePrefixes = getAllKnownCookiePrefixes();

    const storageKeysWithoutKnownPrefix: string[] = Object.keys(storage).filter(
      (savedCookieName) =>
        !allKnownCookiePrefixes.some((knownCookiePrefix: string) => startsWith(savedCookieName, knownCookiePrefix)),
    );

    if (storageKeysWithoutKnownPrefix.length > 0) {
      logger.error(
        `Found unknown keys in ${storageName}: "${storageKeysWithoutKnownPrefix.join('", "')}". These will be deleted.`,
      );
    }
  };

  const handleConsentChange = (
    cookieConsentObject: VanillaCookieConsent.CookieConsent<CookieConsentCategoryValues>,
  ) => {
    if (cookieConsentObject.getUserPreferences().accept_type === VanillaCookieConsent.AcceptType.ALL) {
      return;
    }

    const approvedConsentLevels = cookieConsentObject.get('level');

    let approvedCookiePrefixes: string[] = [];

    approvedConsentLevels.forEach((approvedConsentLevelName: string) => {
      if (Object.prototype.hasOwnProperty.call(cookieNamesByCategory, approvedConsentLevelName)) {
        // @ts-ignore
        approvedCookiePrefixes = approvedCookiePrefixes.concat(cookieNamesByCategory[approvedConsentLevelName]);
      }
    });

    logCookiesWithUnknownPrefixes();
    removeCookiesNotMatchingPrefixes(approvedCookiePrefixes);

    logBrowserStorageKeysWithUnknownPrefixes(localStorage, 'localStorage');
    removeBrowserStorageKeysNotMatchingPrefixes(localStorage, approvedCookiePrefixes);

    logBrowserStorageKeysWithUnknownPrefixes(sessionStorage, 'sessionStorage');
    removeBrowserStorageKeysNotMatchingPrefixes(sessionStorage, approvedCookiePrefixes);
  };

  const clearAllStoragesIfNoConsentWasGivenYet = () => {
    removeCookiesNotMatchingPrefixes([CookiesConstants.LMC_COOKIE_CONSENT_MANAGER_COOKIE]);
    localStorage.clear();
    sessionStorage.clear();
  };

  LmcCookieConsentManager(`atmoskop.${language}`, {
    defaultLang: language,
    autodetectLang: false,
    onAccept: handleConsentChange,
    onChange: handleConsentChange,
    config: {
      hide_from_bots: !navigator?.webdriver,
      cookie_necessary_only_expiration: 30,
    },
    displayMode: DisplayMode.FORCE,
    secondaryButtonMode: SecondaryButtonMode.ACCEPT_NECESSARY,
    translationOverrides: {
      cs: {
        consentTitle: 'Cookies vám lépe pomohou najít práci ve firmě, která vám sedne',
      },
    },
  });

  if (!cookies.get(CookiesConstants.LMC_COOKIE_CONSENT_MANAGER_COOKIE)) {
    clearAllStoragesIfNoConsentWasGivenYet();
  }
};
