import { createContext } from 'react';
import { CookieNamesIndexedByCategory } from '../graphql/generated/types/graphqlTypes';

const CookieConsentCategoriesProvider = createContext<CookieNamesIndexedByCategory>({
  necessary: [],
  ad: [],
  analytics: [],
  functionality: [],
  personalization: [],
});

export default CookieConsentCategoriesProvider;
