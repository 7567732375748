export const startsWith = (string: string, prefix: string) => {
  return string.lastIndexOf(prefix, 0) === 0;
};

export const isString = (value: any): boolean => {
  return Object.prototype.toString.call(value) === '[object String]';
};

export const nonEmptyStringOrNull = (value: any): string | null => {
  if (isString(value)) {
    const stringValue = String(value);

    if (stringValue !== '') {
      return stringValue;
    }
  }

  return null;
};

export const stringOrError = (value: any): string => {
  if (!isString(value)) {
    throw new Error(`Value is of type "${typeof value}" but "string" was expected`);
  }

  return value;
};
