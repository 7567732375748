// We will remove this lib as soon as possible when Sentry will be implemented

import axios from 'axios';
import { generateRoute } from '@libs/localizedRoute';

const logFrontendErrorToApi = (message: string, errorData?: any) =>
  axios.post(generateRoute('cs', 'api.logErrorFromFrontend'), {
    errorMessage: message,
    errorData,
  });

const logger = {
  error: (message: string, errorData?: any) => {
    // eslint-disable-next-line no-console
    console.error(`Error: ${message}`);
    logFrontendErrorToApi(message, errorData);
  },
};

export default logger;
