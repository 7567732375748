import routing from 'fos-router';
import { useTranslation } from 'next-i18next';
import { csRoute, csSlugs } from 'routes/cs/localizedRoutes';
import routesCs from 'routes/generated/fos_js_routes_cs.json';
import { ProfileTabName } from '../graphql/generated/types/graphqlTypes';

export const getLocalizedRoute = (route: string, locale: string) => {
  switch (locale) {
    case 'cs':
      return csRoute[route];
    case 'en':
      return `/en${csRoute[route]}`;
    default:
      return route;
  }
};

export const useGetLocalizedRoute = () => {
  const { i18n } = useTranslation();

  return (route: string) => getLocalizedRoute(route, i18n.language);
};

export const getLocalizedTabSlug = (tabName: ProfileTabName) => {
  return csSlugs[tabName];
};

export const generateRoute = (locale: string, route: string, parameters = {}) => {
  routing.setRoutingData(routesCs);

  return routing.generate(`${route}.${locale}`, parameters, true);
};

export const useGenerateRoute = () => {
  const { i18n } = useTranslation();

  return (route: string, parameters = {}) => generateRoute(i18n.language, route, parameters);
};
