import { ApolloClient, InMemoryCache, NormalizedCacheObject, from } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import createUploadClient from 'apollo-upload-client/createUploadLink.mjs';
import { trimEnd } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

interface Apollo {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  useApolloNetworkStatus: ReturnType<typeof createNetworkStatusNotifier>['useApolloNetworkStatus'];
  lastServerSideStateReference?: any;
}

const getValidHost = (host: string) => {
  const hostWithoutPort = host.replace(/(:.\d+)/, '');
  if (!hostWithoutPort.includes('www.')) {
    return `www.${hostWithoutPort}`;
  }

  return hostWithoutPort;
};

let clientSideApolloSingleton: Apollo;

const isServerSide = (): boolean => {
  return typeof window === 'undefined';
};

const createApollo = (domain: string, locale: string, rawCookiesForSsr: string | undefined): Apollo => {
  const host = getValidHost(process.env.NODE_ENV === 'development' ? process.env.APP_DOMAIN ?? '' : domain);

  const credentials = 'same-origin';
  const userLocale = locale;
  let headers: {} = { host, userLocale };

  if (rawCookiesForSsr) {
    headers = { ...headers, Cookie: rawCookiesForSsr };
  }

  const { link: networkStatusNotifierLink, useApolloNetworkStatus } = createNetworkStatusNotifier();

  const httpLink = isServerSide()
    ? new BatchHttpLink({
        uri: `${trimEnd(process.env.API_URL, '/')}/batch`,
        credentials,
        headers,
      })
    : createUploadClient({
        uri: process.env.API_URL,
        credentials,
        headers,
      });

  const link = from([networkStatusNotifierLink, httpLink]);

  return {
    apolloClient: new ApolloClient({
      ssrMode: isServerSide(),
      uri: process.env.API_URL,
      cache: new InMemoryCache(),
      link,
      credentials,
      headers,
    }),
    useApolloNetworkStatus,
  };
};

export const initializeApollo = (
  domain: string,
  locale: string,
  rawCookiesForSsr: string | undefined,
  serverSideState: any = null,
): Apollo => {
  const apollo = clientSideApolloSingleton ?? createApollo(domain, locale, rawCookiesForSsr);
  if (typeof window !== 'undefined' && !clientSideApolloSingleton) {
    clientSideApolloSingleton = apollo;
  }

  const { apolloClient } = apollo;

  // When `serverSideState` reference changes, it means that `getServerSideProps` was called.
  // In this case `serverSideState` contains cache with newer data than `clientSideState`.
  if (serverSideState && apollo.lastServerSideStateReference !== serverSideState) {
    const clientSideState = apolloClient.extract();

    const state = {
      ...clientSideState,
      ...serverSideState,
      ROOT_QUERY: {
        ...clientSideState.ROOT_QUERY,
        ...serverSideState.ROOT_QUERY,
      },
      ROOT_MUTATION: {
        ...clientSideState.ROOT_MUTATION,
        ...serverSideState.ROOT_MUTATION,
      },
    };

    apolloClient.cache.restore(state);
    apollo.lastServerSideStateReference = serverSideState;
  }

  return apollo;
};

export const useApollo = (serverSideState: any) => {
  const domain = typeof window === 'undefined' ? '' : window.location.hostname;
  const { i18n } = useTranslation();

  return useMemo(
    () => initializeApollo(domain, i18n.language, undefined, serverSideState),
    [serverSideState, domain, i18n.language],
  );
};
