const ICU = require('i18next-icu/index');
const translationsCS = require('./public/locales/translations.cs.json');
const translationsEN = require('./public/locales/translations.en.json');
const translationsPL = require('./public/locales/translations.pl.json');

const icu = new ICU();

class DummyTranslationLoadingBackend {}
DummyTranslationLoadingBackend.type = 'backend';

module.exports = {
  i18n: {
    locales: ['cs', 'en'],
    defaultLocale: 'cs',
    localeDetection: false,
  },
  use: [
    icu,
    DummyTranslationLoadingBackend, // Used to prevent default loading from public/locale/{lng}/{ns}.json files.
  ],
  interpolation: {
    escapeValue: false,
  },
  keySeparator: false,
  defaultNS: 'messages',
  resources: {
    cs: translationsCS,
    en: translationsEN,
    pl: translationsPL,
  },
  returnNull: false,
  serializeConfig: false, // See: https://github.com/i18next/next-i18next/tree/v13.2.2#unserializable-configs
};
