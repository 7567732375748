import * as Sentry from '@sentry/nextjs';

export const initSentry = (sentryDns: string, environment: string | undefined) => {
  Sentry.init({
    dsn: sentryDns,
    tracesSampleRate: 1.0,
    environment: environment || 'unknown',
    ignoreErrors: [
      // Network errors such as going offline or being blocked by a proxy
      'Failed to fetch',
      // @see https://bugs.webkit.org/show_bug.cgi?id=246010
      /.*@webkit-masked-url.*/,
    ],
    denyUrls: [
      // Browser extensions
      /chrome-extension:\/\//,
      /safari-web-extension:\/\//,
      /moz-extension:\/\//,
    ],
  });
};
