import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const removeCookieFromCurrentDomainAndAncestors = (cookieName: string) => {
  const domainParts = window.location.hostname.split('.');

  while (domainParts.length > 0) {
    cookies.remove(cookieName, { path: '/', domain: domainParts.join('.') });
    domainParts.shift();
  }

  cookies.remove(cookieName, { path: '/' });
};
