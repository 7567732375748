import { createContext } from 'react';
import { RequestContextInformation } from '../graphql/generated/types/graphqlTypes';

export type RequestContextData =
  | (RequestContextInformation & {
      hostname: string;
    })
  | null;

export const RequestContext = createContext<RequestContextData>(null);
